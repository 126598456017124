<template>
  <div class="input-bonus-radio" :class="{ 'is-checked': !!props.value }">
    <label class="input-bonus-radio__label">
      <input
        class="input-bonus-radio__input"
        type="radio"
        :name="props.name"
        :disabled="props.isDisabled"
        :checked="!!props.value"
        @change="onChange"
      >
    </label>
  </div>
</template>

<script setup lang="ts">
  const props = defineProps<{
    id: string;
    isDisabled?: boolean,
    name: string,
    value: any
  }>();
  
  const emit = defineEmits(['change', 'update:value']);
  const onChange = (e:any):void => {
    emit('change', e.target.checked);
    emit('update:value', e.target.checked ? 1 : '');
  };
</script>

<style src="~/assets/styles/components/form/input/bonus-radio.scss" lang="scss" />

